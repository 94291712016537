import { create } from 'zustand'

export interface Message {
  title: string;
  content: string;
  lifeTime: number;
}

interface ToastStoreState {
  messages: Message[];
  addMessage: (message: Message) => Promise<void>;
}

// curried version https://stackoverflow.com/questions/69814018/zustand-typescript-persist-how-to-type-store
const useToastStore = create<ToastStoreState>()((set, get) => ({
  messages: [],
  addMessage: async (message) => {
    const removeMessageAfterTimeout = () => {
      // find the message in the list and remove it
      const newMessages = JSON.parse(JSON.stringify(get().messages));
      const index = newMessages.findIndex((m: Message) => m === message);
      newMessages.splice(index, 1);
      set({ messages: newMessages });
    };
    const newMessages = JSON.parse(JSON.stringify(get().messages));
    newMessages.push(message);
    setTimeout(removeMessageAfterTimeout, message.lifeTime || 5000);
    set({ messages: newMessages });
  },
}));

export { useToastStore };
