import React, { useState, PropsWithChildren, ReactNode } from "react";
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogContent from '@mui/joy/DialogContent';
import { Trans } from "@lingui/macro";
import Button from '@mui/joy/Button';
import QRCode from "react-qr-code";
import { DialogActions, DialogTitle } from "@mui/joy";

const QRCodeModal = ({ value, children, title }: PropsWithChildren<{ value: string, title?: ReactNode }>) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <span onClick={() => setOpen(true)} >{children}</span>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{zIndex: 9999} }
        >
          <ModalDialog>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent sx={{ backgroundColor: "white" }}><QRCode size={2048} style={{ padding: "5vh", maxHeight: "80vh", maxWidth: "60vw" }} value={value} onClick={() => setOpen(false)} /></DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setOpen(false)}><Trans>Close</Trans></Button>
            </DialogActions>
          </ModalDialog>
        </Modal >
    </>
  );
}

export { QRCodeModal };
