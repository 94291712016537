import * as React from 'react';
import {useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { useSearchParams, useParams } from "react-router-dom";
import { Breadcrumb } from '../../components/Breadcrumb';
import { SessionContextTable } from '../../components/SessionContextTable';
import { useEffect } from 'react';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { SessionContextPrefilter, SessionContextSortField, SortDirection } from '../../components/ListTypes';
import { SessionContext } from '../../models/SessionContext';
import { SessionContextFilter } from '../../components/SessionContextFilter';
import { Profile } from '../../models/Profile';
import { Device } from '../../models/Device';

const SessionContextListPage = () => {
  const fetch = useAuthenticatedFetch();
  const {prefilter} = useParams();
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [sessionContexts, setSessionContexts] = useState<SessionContext[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);
  const [sort, setSort] = useState<SessionContextSortField>(SessionContextSortField.CreatedAt);
  const [deviceFilter, setDeviceFilter] = useState<Device | null>(null);
  const [userFilter, setUserFilter] = useState<Profile | null>(null);

  const { _ } = useLingui();
  let sessionPrefilter: SessionContextPrefilter;

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof SessionContextSortField;
    if (sort !== SessionContextSortField[typedSortField]) {
      setSort(SessionContextSortField[typedSortField]);
    }
  }

  // if filters is is not set or contains a invalid value, set it to all
  if (!prefilter || ["all", "active", "closed"].indexOf(prefilter) < 0) {
    sessionPrefilter = SessionContextPrefilter.All;
  } else {
    sessionPrefilter = SessionContextPrefilter[prefilter as keyof typeof SessionContextPrefilter];
  }



  useEffect(() => {
    const getSessions = async () => {
      await setLoading(true)
      let url = `/api/SessionContext?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}`;
      if (deviceFilter) url += `&device=${deviceFilter.id}`;
      if (userFilter) url += `&user=${userFilter.id}`;

      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessions = await result.json();
        await setSessionContexts(sessions.items);
        await setTotalCount(sessions.totalCount);
        await setSkip(sessions.skip);
        await setTake(sessions.take);
        await setLoading(false);
      }
    };
    getSessions();
  }, [skip, take, sortDirection, sort, prefilter, fetch, deviceFilter, userFilter]);


  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Breadcrumb crumbs={[{ title: _(msg`All sessions contexts`) }]} />
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            <Trans>Session contexts</Trans>
          </Typography>
        </Box>
        {loading ?
          <LoadingIndicator /> :
          <>
            <SessionContextFilter selectedUser={userFilter} selectedDevice={deviceFilter} onDeviceChanged={(device: Device | null) => setDeviceFilter(device)} onUserChanged={(user: Profile | null) => setUserFilter(user)} />
            <SessionContextTable sessionContexts={sessionContexts} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={sessionPrefilter} />
          </>
        }
      </Box>
    </Box>
  );
}

export { SessionContextListPage };
