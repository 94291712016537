import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Breadcrumb } from "../../components/Breadcrumb";

import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ErrorOutlined } from '@mui/icons-material';
import { WebHook, WebHookAuthenticationType, WebHookMethod, WebHookType } from '../../models/WebHook';
import { useAuthenticatedFetch } from '../../stores/fetch';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { useParams } from "react-router-dom";
import Editor from '@monaco-editor/react';
import { PlaceholderLegend } from './PlaceholderLegend';

export function WebHookPage() {
  const { _ } = useLingui();
  const [error, setError] = useState<JSX.Element | null>(null);
  const [webHook, setWebHook] = useState<WebHook>({} as WebHook);
  const editorRef = useRef<any>();
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();

  useEffect(() => {
    if (id === "new") {
      setWebHook({ type: WebHookType.SessionCreated } as WebHook);
    } else {
      // load webHook from backend
      const getWebHook = async () => {
        let url = `/api/WebHook/${id}`;
        const result = await fetch(url, { method: "GET" });
        if (result) {
          let webHook = await result.json();
          webHook.type = WebHookType[webHook.type as unknown as keyof typeof WebHookType];
          webHook.authenticationType = WebHookAuthenticationType[webHook.authenticationType as unknown as keyof typeof WebHookAuthenticationType];
          webHook.method = WebHookMethod[webHook.method as unknown as keyof typeof WebHookMethod];

          setWebHook(webHook);
        }
      };
      getWebHook();
    }
  }, [fetch, id]);

  const saveWebHook = async () => {
    const webHookToSave = { ...webHook };

    const answer = await fetch("/api/WebHook", {
      method: id === "new" ? "POST" : "PATCH",
      body: JSON.stringify(webHookToSave),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (answer.ok) {
      navigate("/organisation/webhooks");
    } else {
      const errors = await answer.json();
      const errorText = errors.title;
      setError(<><ErrorOutlined />{errorText}</>);
    }
  };

  const updateWebHookModel = (field: keyof WebHook, value: string) => {
    if (!webHook) return;

    let newWebHook = { ...webHook };
    (newWebHook[field] as any) = value as any; // i don't know, whya this works not as expected when removing the cast
    setWebHook(newWebHook)
  };

  function handleEditorDidMount(editor: any) {
    editorRef.current = editor;
  }

  const updateWebHookType = (value: WebHookType) => {
    if (!webHook) return;

    let newWebHook = { ...webHook };
    newWebHook.type =value;
    setWebHook(newWebHook)
  }

  const updateWebHookMethod = (value: WebHookMethod) => {
    if (!webHook) return;

    let newWebHook = { ...webHook };
    newWebHook.method = value;
    setWebHook(newWebHook)
  }

  const updateWebHookAuthenticationType = (value: WebHookAuthenticationType) => {
    if (!webHook) return;

    let newWebHook = { ...webHook, username: "", password: "" };
    newWebHook.authenticationType = value;
    setWebHook(newWebHook)
  }

  const crumbs = [{ title: _(msg`My Organisation`), link: "/organisation" }, { title: _(msg`WebHooks`), link: "/organisation/webhooks" }];
  if (id === "new") {
    crumbs.push({ title: _(msg`New WebHook`), link: "/webhook/new" });
  } else {
    crumbs.push({ title: _(msg`Edit WebHook`), link: `/webhook/${webHook.name}` });
  }

  const onInsert = (text: string) => {
    // insert the text at the current editor position
    if (editorRef.current) {
      const selection = editorRef.current.getSelection();
      editorRef.current.executeEdits("", [{ range: selection, text: text, forceMoveMarkers: true }]);
    }
  }

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2">{id === "new" ? <Trans>New WebHook</Trans> : <Trans>Edit WebHook</Trans>}</Typography>
      </Box>
      <Box>
        <Stack gap={4} sx={{ mt: 2 }}>
          <form>
            <FormControl required>
              <FormLabel><Trans>WebHook Name</Trans></FormLabel>
              <Input
                onChange={e => updateWebHookModel("name", e.target.value)}
                id="name"
                value={webHook!.name}
              />
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel><Trans>Webhook HTTP Method</Trans></FormLabel>
              <Select
                required
                onChange={(e, value) => updateWebHookMethod(value!)}
                value={webHook.method}
              >
                <Option value={WebHookMethod.GET}>Get</Option>
                <Option value={WebHookMethod.POST}>Post</Option>
                <Option value={WebHookMethod.PUT}>Put</Option>
                <Option value={WebHookMethod.DELETE}>Delete</Option>
                <Option value={WebHookMethod.PATCH}>Patch</Option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel><Trans>URL to call</Trans></FormLabel>
              <Input
                onChange={e => updateWebHookModel("url", e.target.value)}
                id="url"
                value={webHook!.url}
              />
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel><Trans>Webhook type</Trans></FormLabel>
              <Select
                required
                onChange={(e, value) => updateWebHookType(value!)}
                value={webHook.type}
              >
                <Option value={WebHookType.SessionCreated}><Trans>Session Created</Trans></Option>
                <Option value={WebHookType.SessionStarted}><Trans>Session Started</Trans></Option>
                <Option value={WebHookType.SessionEnded}><Trans>Session Ended</Trans></Option>
                <Option value={WebHookType.SessionContextCreated}><Trans>Session Context Created</Trans></Option>
                <Option value={WebHookType.SessionContextClosed}><Trans>Session Context Closed</Trans></Option>
                <Option value={WebHookType.ParticipantJoinedSession}><Trans>Participant Joined Session</Trans></Option>
                <Option value={WebHookType.ParticipantLeftSession}><Trans>Participant Left Session</Trans></Option>
                <Option value={WebHookType.BeforeParticipantJoinsSession}><Trans>Before Participant Joins Session</Trans></Option>
              </Select>
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel><Trans>Webhook Authentication type</Trans></FormLabel>
              <Select
                required
                onChange={(e, value) => updateWebHookAuthenticationType(value!)}
                value={webHook.authenticationType}
              >
                <Option value={WebHookAuthenticationType.None}><Trans>None</Trans></Option>
                <Option value={WebHookAuthenticationType.Basic}><Trans>Basic</Trans></Option>
                <Option value={WebHookAuthenticationType.Bearer}><Trans>Bearer Token</Trans></Option>
              </Select>
            </FormControl>
            <FormControl sx={{ display: (webHook.authenticationType === WebHookAuthenticationType.Basic) ? "block" : "none" }} >
              <FormLabel><Trans>Username</Trans></FormLabel>
              <Input
                onChange={e => updateWebHookModel("username", e.target.value)}
                id="username"
                value={webHook!.username}
              />
            </FormControl>
            <FormControl sx={{ display: (webHook.authenticationType === WebHookAuthenticationType.Basic) ? "block": "none" }} >
              <FormLabel><Trans>Password</Trans></FormLabel>
              <Input
                onChange={e => updateWebHookModel("password", e.target.value)}
                id="password"
                type="password"
                value={webHook!.password}
              />
            </FormControl>

            <FormControl sx={{ display: (webHook.authenticationType === WebHookAuthenticationType.Bearer) ? "block" : "none" }} >
              <FormLabel><Trans>Bearer Token</Trans></FormLabel>
              <Input
                onChange={e => updateWebHookModel("password", e.target.value)}
                id="bearer"
                value={webHook!.password}
              />
            </FormControl>

            <FormControl>
              <FormLabel><Trans>Template</Trans></FormLabel>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={9}>
                  <Editor
                    height="30vh"
                    defaultLanguage="json"
                    theme="vs-dark"
                    onMount={handleEditorDidMount}
                    value={webHook.template}
                    onChange={newValue => {
                        if (newValue) updateWebHookModel("template", newValue);
                      }
                    }
                      />
                </Grid>
                <Grid xs={3}>
                  <PlaceholderLegend webHookType={webHook.type} onInsert={onInsert} />
                </Grid>
              </Grid>

            </FormControl>
            {error}
            <Stack gap={4} sx={{ mt: 2 }}>
              <Button onClick={() => saveWebHook()} fullWidth>
                <Trans>Save WebHook</Trans>
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
    </>
  );
}
