import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Breadcrumb } from "../../components/Breadcrumb";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Select, Option } from '@mui/joy';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { SessionContextRecordingMode } from '../../models/SessionContext';

export function StartNewSessionPage() {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  const [shortUrl, setShortUrl] = useState<string>('');
  const [friendlyName, setFriendlyName] = useState<string>('');
  const [hqVideo, setHqVideo] = useState<boolean>(true);
  const [recordingMode, setRecordingMode] = useState<SessionContextRecordingMode>(SessionContextRecordingMode.Always);
  const [modalOpen, setModalOpen] = useState(false);

  let openSessionContext = async () => {
    const searchParam = hqVideo ? "" : "?lq=true";
    // check, if the session exists
    const session = await fetch(`/api/sessioncontext/byShortUrl/${shortUrl}`,
      {
        method: "GET",
      });
    if (session.status !== 200) {
      setModalOpen(true);
      return;
    };

    return navigate(`/livesession/${shortUrl}${searchParam}`);
  }

  let newSessionContext = async () => {
    const searchParam = hqVideo ? "" : "?lq=true";
    const session = await fetch(`/api/sessioncontext`,
      {
        method: "POST",
        body: JSON.stringify({
          friendlyName: friendlyName,
          recordingMode: recordingMode,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    if (session.status !== 200) {
      return;
    };
    const newSessionId = (await session.json()).shortURL;

    return navigate(`/livesession/${newSessionId}${searchParam}`);
  }

  const updateShortUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    // check if value only contains digits
    if (!/^\d+$/.test(e.target.value) && e.target.value !== "") {
      return;
    }
    setShortUrl(e.target.value);
  };

  return (
    <>
      <Breadcrumb crumbs={[{ title: _(msg`Sessions`), link: "/" }]} />
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2"><Trans>Ad-hoc Session</Trans></Typography>
      </Box>
      <Box>
        <Stack gap={4} sx={{ mt: 2 }}>
          <form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'left',
                paddingBottom: "20px",
                flexDirection: { xs: 'column', sm: 'row' },

              }}
            >
              <FormControl sx={{ flexGrow: 1, padding: "10px" }}>
                <FormLabel><Trans>HQ Video</Trans></FormLabel>
                <Checkbox size="sm" checked={!!hqVideo} onChange={() => setHqVideo(!hqVideo)} label={_(msg`Send HQ video`)} name="persistent" />
              </FormControl>

              <FormControl sx={{ flexGrow: 1, padding: "10px" }}>
                <FormLabel><Trans>Recording</Trans></FormLabel>
                <Select
                  required
                  onChange={(e, value) => setRecordingMode(value!)}
                  defaultValue={SessionContextRecordingMode.Always}
                >
                  <Option value={SessionContextRecordingMode.Always}><Trans>Always</Trans></Option>
                  <Option value={SessionContextRecordingMode.Never}><Trans>Never</Trans></Option>
                  <Option value={SessionContextRecordingMode.WebHook}><Trans>Set by Webhook</Trans></Option>
                </Select>
              </FormControl>

              <FormControl sx={{ flexGrow: 1, padding: "10px" }}>
                <FormLabel><Trans>Context name</Trans></FormLabel>

                <Input onChange={(event) => setFriendlyName(event.target.value)} placeholder={"Leave empty for autogenerated name"} id="friendlyName" value={friendlyName} />
              </FormControl>
              
            </Box>
            <Button onClick={() => newSessionContext()} fullWidth>
              <Trans>Create ad-hoc Session</Trans>
            </Button>
          </form>
          <Divider />
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2"><Trans>Join Session</Trans></Typography>
      </Box>
      <Box>
        <Stack gap={4} sx={{ mt: 2 }}>
          <FormControl required>
            <FormLabel><Trans>Session ID</Trans></FormLabel>
            <Input onChange={updateShortUrl} id="shortUrl" value={shortUrl} />
          </FormControl>
          <Stack gap={4} sx={{ mt: 2 }}>
            <form>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: "10px"
                }}
              >
                <Checkbox size="sm" checked={!!hqVideo} onChange={() => setHqVideo(!hqVideo)} label={_(msg`Send HQ video`)} name="persistent" />
              </Box>
              <Button disabled={!shortUrl} onClick={() => openSessionContext()} fullWidth>
                <Trans>Join Session</Trans>
              </Button>
            </form>
          </Stack>
        </Stack>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <ModalDialog>
          <DialogTitle><Trans>The session you want to join does not exist.</Trans></DialogTitle>
          <DialogContent><Trans>The session code you entered is invalid. Either the session does not exist or has already finished.</Trans></DialogContent>
          <DialogActions>
            <Button color="success" onClick={() => { setModalOpen(false); }}><Trans>Okay</Trans></Button>
          </DialogActions>
        </ModalDialog>

      </Modal>
    </>
  );
}
