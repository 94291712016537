import React, { ReactElement, useState } from 'react';
import Button from '@mui/joy/Button';
import { Trans } from "@lingui/macro";
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { Organisation } from '../models/Organisation';
import { Typography } from '@mui/joy';
import { useAuthenticatedFetch } from '../stores/fetch';
import { FormControl, FormLabel, Input, Stack, DialogActions } from '@mui/joy';


const InviteUserButton = ({ organisation, onFinished }: { organisation: Organisation, onFinished?: Function }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailInvalid, setEmailInvalid] = useState<boolean>(true);
  const [error, setError] = useState<ReactElement>(<></>);
  const fetch = useAuthenticatedFetch();

  const onAddClick = async () => {
    // create the user
    const result = await fetch("/api/Organisation/invite", {
      method: "POST",
      body: JSON.stringify({email, role: "User"}),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!result.ok) {
      if (result.text) {
        console.error(await result.text());
      } else {
        console.error(result);
      }
      setError(<Trans>Failed to invite user</Trans>);
      return;
    }
    const user = await result.json();
    if (user) {
      setError(<></>);
      setOpen(false);
      onFinished && onFinished();
    }
  }

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const updateEmail = (email: string) => {
    if (!validateEmail(email)) {
      setEmailInvalid(true);
      setError(<Trans>Email address is invalid.</Trans>);
    } else {
      setEmailInvalid(false);
      setError(<></>);
    }
    setEmail(email);
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}><Trans>Invite User</Trans></Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <ModalDialog>
          <DialogTitle><Trans>Add User</Trans></DialogTitle>
          <DialogContent><Trans>Please enter the following information to add a new user to your organisation. <br/>Please keep in mind, that the user's email must not be connected to an existing user in this app.</Trans></DialogContent>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input error={emailInvalid} autoFocus required type="email" onChange={(event) => updateEmail(event.target.value)} />
            </FormControl>
          </Stack>
          {error && <Typography>{error}</Typography>}
          <DialogActions>
            <Button color="primary" disabled={emailInvalid} onClick={() => onAddClick()}><Trans>Invite user</Trans></Button>
            <Button color="neutral" onClick={() => setOpen(false)}><Trans>Cancel</Trans></Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};


export { InviteUserButton };
