import React from "react";
import Snackbar from '@mui/joy/Snackbar';
import { useToastStore } from "../stores/ToastStore";
import { Typography } from "@mui/material";

const ToastRenderer = () => {
  const messages = useToastStore(store => store.messages);

  return (<>
    {messages.map((message, index) =>
      <Snackbar
        variant="solid"
        color="danger"
        open={true}
        sx={{ height: "80px", bottom: index * 100 + 10, transitionProperty: "bottom", transitionDuration: "0.5", flexDirection: "column", display: "flex" }}
      >
        <Typography variant="h4">
          {message.title}
        </Typography>
        <Typography paragraph={true} variant="subtitle1">
          {message.content}
        </Typography>
      </Snackbar>
    )}
  </>);
}

export { ToastRenderer }
