import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from "@lingui/macro";
import { LoadingIndicator } from '../../components/LoadingIndicator';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Table from '@mui/joy/Table';
import { formatDateWithSeconds } from '../../utils/dateUtils';

interface Log {
  id: string;
  timestamp: string;
  severity: LogSeverity;
  message: string;
}

enum LogSeverity {
  Fatal = "Fatal",
  Error = "Error",
  Warning = "Warning",
  Information = "Information",
  Debug = "Debug",
  Verbose = "Verbose",
};

const LogTab = () => {
  const [logs, setLogs] = useState<Log[] | null>(null);
  const fetch = useAuthenticatedFetch();

  useEffect(() => {
    const loadTokensFromBackend = async () => {
      const result = await fetch("/api/Log", { method: "GET" });
      const logs: Log[] = await result.json();
      setLogs(logs);
    };

    loadTokensFromBackend();
  }, [fetch]);

  return (
    <>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
      <Card>
        <Typography level="title-md"><Trans>Server logs</Trans></Typography>
        <Typography level="body-sm">
          <Trans>You can see the last 1000 log messages here.<br />If you need more information, please refer to the log files in the application's folder.</Trans>
        </Typography>

        {logs ?
          <Box sx={{
            overflow: 'auto',
            minHeight: '100px',
          }} >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '10%' }} />
                <col />
              </colgroup>

              <thead>
                <tr>
                  <th><Trans>Date</Trans></th>
                  <th><Trans>Severity</Trans></th>
                  <th><Trans>Message</Trans></th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <LogRowUI key={log.id} log={log} />
                ))}
              </tbody>
            </Table>
          </Box>
          :
          <LoadingIndicator />
        }
        </Card>
      </Stack>
    </>
  );
}

const LogRowUI = ({ log }: { log: Log }) => {
  const theme = useTheme();
  let bgColor: string;
  let color: string;

  switch (log.severity) {
    case LogSeverity.Fatal:
      bgColor = theme.vars.palette.danger.solidBg;
      color = theme.vars.palette.danger.solidColor;
      break;
    case LogSeverity.Error:
      bgColor = theme.vars.palette.danger.softBg;
      color = theme.vars.palette.danger.softColor;
      break;
    case LogSeverity.Warning:
      bgColor = theme.vars.palette.warning.softBg;
      color = theme.vars.palette.warning.softColor;
      break;
    case LogSeverity.Debug:
      bgColor = theme.vars.palette.neutral.softBg;
      color = theme.vars.palette.neutral.softColor;
      break;
    default:
      bgColor = theme.vars.palette.background.body;
      color = theme.vars.palette.text.primary;
      break;
  }

  return (
    <tr style={{
      backgroundColor: bgColor,
      color: color
    }}>
      <td><Typography level="body-xs" textColor={color}>{formatDateWithSeconds(log.timestamp)}</Typography></td>
      <td><Typography level="body-xs" textColor={color}>{log.severity}</Typography></td>
      <td><Typography level="body-xs" textColor={color}>{log.message}</Typography></td>
    </tr>
  )
}

export { LogTab };
