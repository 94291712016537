import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from "@lingui/macro";
import CircularProgress from '@mui/joy/CircularProgress';
import { Device } from "../../models/Device";
import { formatDate } from "../../utils/dateUtils";
import { Session } from "../../models/Session";
import { useSearchParams } from "react-router-dom";
import { SortDirection, SessionSortField, SessionPrefilter } from "../../components/ListTypes";
import { SessionTable } from "../../components/SessionTable";
import { SessionFilter } from "../../components/SessionFilter";
import { Profile } from "../../models/Profile";
import { SessionContext, SessionContextState } from "../../models/SessionContext";
import { CloseSessionContextButton } from "./CloseSessionContextButton";
import { SessionContextStateChip } from "../../components/SessionContextStateChip";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Link as RouterLink } from 'react-router-dom';
import {Button } from '@mui/joy';


const SessionListTab = () => {
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const prefilter = SessionPrefilter.All;
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);
  const [sort, setSort] = useState<SessionSortField>(SessionSortField.CreatedAt);
  const [userFilter, setUserFilter] = useState<Profile | null>(null);
  const [deviceFilter, setDeviceFilter] = useState<Device | null>(null);

  useEffect(() => {
    const getSessionContext = async () => {
      let url = `/api/SessionContext/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessionContext = await result.json();
        setSessionContext(sessionContext);
      }
    };
    getSessionContext();
  }, [id, fetch]);

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof SessionSortField;
    if (sort !== SessionSortField[typedSortField]) {
      setSort(SessionSortField[typedSortField]);
    }
  }

  useEffect(() => {
    const getSessions = async () => {
      await setLoading(true)
      let url = `/api/SessionContext/${id}/Sessions?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}`;
      if (userFilter) url += `&user=${userFilter.id}`;
      if (deviceFilter) url += `&device=${deviceFilter.id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessions = await result.json();
        await setSessions(sessions.items);
        await setTotalCount(sessions.totalCount);
        await setSkip(sessions.skip);
        await setTake(sessions.take);
        await setLoading(false)
      }
    };
    getSessions();
  }, [skip, take, sortDirection, sort, prefilter, fetch, id, userFilter, deviceFilter]);

  return !sessionContext
    ? <LoadingIndicator />
    : <>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={9}>
          <Card>
            <Typography level="title-lg"><Trans>Sessions in Context "{sessionContext.friendlyName}" ({sessionContext.shortURL})</Trans></Typography>
            {loading ?
              <div style={{ display: "flex", height: "70vh" }}><div style={{ margin: 'auto', textAlign: "center" }}><CircularProgress variant="soft" /><br /><Trans>Loading...</Trans></div></div> :
              <>
                <SessionFilter selectedUser={userFilter} selectedDevice={deviceFilter} onDeviceChanged={(device: Device | null) => setDeviceFilter(device)} onUserChanged={(user: Profile | null) => setUserFilter(user)} />
                <SessionTable sessions={sessions} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={prefilter} />
              </>
            }
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card>
            <Typography level="title-lg"><Trans>Session context information</Trans></Typography>
            <Typography level="body-sm"><Trans>Context ID: {sessionContext.id}</Trans></Typography>
            <Typography level="body-sm"><Trans>State: <SessionContextStateChip state={sessionContext.state} /></Trans></Typography>
            <Typography level="body-sm"><Trans>ShortURL: {id}</Trans></Typography>
            <Typography level="body-sm"><Trans>Created at: {formatDate(sessionContext.createdAt)}</Trans></Typography>
            <Typography level="body-sm"><Trans>Organisation: {sessionContext.organisationName}</Trans></Typography>
            {(sessionContext.state !== SessionContextState.Closed) && <>
              <CloseSessionContextButton sessionContext={sessionContext} onFinished={() => { }} />
              <Button component={RouterLink} to={`/livesession/${sessionContext.shortURL}`}><Trans>Enter session</Trans></Button>
            </>
            }
          </Card>

        </Grid>
      </Grid>
    </>
}

export { SessionListTab };
