import Box from '@mui/joy/Box';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListDivider from '@mui/joy/ListDivider';
import { MobilePagination } from './MobilePagination';
import { Trans } from "@lingui/macro";
import { SortDirection, WebHookPrefilter, WebHookSortField } from './ListTypes';
import {formatDateWithAgo} from '../utils/dateUtils';
import { WebHook } from '../models/WebHook';
import { ConfirmDeleteButton } from './ConfirmDeleteButton';

import { IconButton } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';

export function WebHookList({ webhooks = [], skip = 0, take = 20, totalCount, sort, sortDirection = SortDirection.Ascending, prefilter, onDeleteWebHook }: { webhooks: WebHook[], skip: number, take: number, totalCount: number, sort: WebHookSortField, sortDirection: SortDirection, prefilter: WebHookPrefilter, onDeleteWebHook: Function }) {
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {webhooks.map((listItem) => (
        <List
          key={listItem.id}
          size="sm"
          sx={{
            '--ListItem-paddingX': 0,
          }}
        >
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
              <div>
                <Typography fontWeight={600} gutterBottom>
                  {listItem.name}
                </Typography>
                <Typography level="body-xs" gutterBottom>
                  {listItem.url}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 0.5,
                    mb: 1,
                  }}
                >
                  <Typography level="body-xs"><Trans>Started at: {formatDateWithAgo(listItem.createdAt)}</Trans></Typography>
                  <Typography level="body-xs"></Typography>
                  <Typography level="body-xs"><Trans>Finished at: {listItem.url}</Trans></Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <IconButton component={RouterLink} to={`/webhook/${listItem.id}`} aria-label="edit">
                    <EditIcon />
                  </IconButton>

                  <ConfirmDeleteButton onDeleteClick={() => onDeleteWebHook(listItem.id)}></ConfirmDeleteButton>
                </Box>
              </div>
            </ListItemContent>
          </ListItem>
          <ListDivider />
        </List>
      ))}
      <MobilePagination skip={skip} take={take} totalCount={totalCount} prefilter={prefilter} sort={sort} sortDirection={sortDirection} />
    </Box>
  );
}
