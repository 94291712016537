import React, { useState } from "react";
import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Device } from "../../models/Device";
import { useAuthenticatedFetch } from "../../stores/fetch";
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { DialogActions, Button } from "@mui/joy";
import { Trans } from "@lingui/macro";
import { QRCodeChipDecorator } from "../../components/QRCodeChipDecorator";


const DeviceTokenChip = ({ device, onNewToken }: { device: Device, onNewToken: Function }) => {
  const fetch = useAuthenticatedFetch();
  const [modalOpen, setModalOpen] = useState(false);  
  const renewToken = async () => {
    const answer = await fetch(`/api/Device/renewtoken`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: device.id, validityInDays: 365})
    });
    if (answer.ok) {
      const device = await answer.json();
      onNewToken && onNewToken(device);
    }
  }
  const url = `${window.location.protocol}//${window.location.host}`;

  return (
    <>
      <Chip
        variant="outlined"
        color="primary"
        endDecorator={<QRCodeChipDecorator title={<Trans>Device token for {device.name}</Trans>} value={`qvra://c?t=${device.deviceToken}&u=${url}`} />}
        startDecorator={
          <ChipDelete
            color="success"
            variant="plain"
            onClick={() => setModalOpen(true)}
          >
            <RefreshIcon />
          </ChipDelete>
        }
      >
        {device.deviceToken}
      </Chip>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <ModalDialog>
          <DialogTitle><Trans>Are you sure, you want to refresh the token for this device?</Trans></DialogTitle>
          <DialogContent><Trans>You can't undo this. You need to apply this token to your device or it can't connect to the server after this action.</Trans></DialogContent>
          <DialogActions>
            <Button color="danger" onClick={() => { renewToken(); setModalOpen(false); }}><Trans>Create a new token</Trans></Button>
            <Button color="neutral" onClick={() => setModalOpen(false)}><Trans>Cancel</Trans></Button>
          </DialogActions>
        </ModalDialog>

      </Modal>
    </>
  );
};

export { DeviceTokenChip };
