import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { SignalRContext } from "../../App";
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Alert from '@mui/joy/Alert';
import { Trans } from "@lingui/macro";
import { HubConnectionState } from '@microsoft/signalr';
import Peer from "simple-peer";
import { useToastStore } from "../../stores/ToastStore";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useNavigate } from 'react-router-dom';
import MessagesPane from '../../components/chat/MessagesPane';
import { ChatMessage } from '../../models/ChatMessage';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { SfuSessionParticipantInformation, SignalRRequestResult } from './ViewModels';
import { RecordingControl } from './RecordingControl';
import { CreateFile, File } from '../../models/File';
import { getClickCoordinatesOnVideo } from './videoTagClickHelper';
import { CardContent, Tooltip } from '@mui/joy';
import { Fullscreen, FullscreenExit, Mic, MicOff, ScreenShare, StopScreenShare } from '@mui/icons-material';

interface UserInformation {
  userName: string,
  email: string,
  firstName: string,
  lastName: string,
  deviceName: string
}
interface PeerInformation {
  Stream: MediaStream,
  VideoTag: HTMLVideoElement,
  PeerContainer: HTMLDivElement,
  ConnectionLost: boolean,
  UserInformation: UserInformation | null
}

interface MetaData {
  audioMid: string,
  videoMid: string,
}
interface PeerInformations {
  [index: string]: PeerInformation
}

interface DanglingTrackInformation {
  track: MediaStreamTrack,
  mid: string
}

function LiveSessionPage({ onConnectionError }: { onConnectionError?: Function }) {
  let [localStream, setLocalStream] = useState<MediaStream | undefined>(undefined);
  let [screenSharingStream, setScreenSharingStream] = useState<MediaStream | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const [iceServers, setIceServers] = useState<any>(null);
  const fullscreenElementRef = useRef<HTMLDivElement>(null);
  const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false);
  let [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const { id: urlShortCode } = useParams();
  const selfView = useRef<HTMLVideoElement>(null);
  const [signalR] = useContext(SignalRContext);
  let [sessionParticipantInformation, setSessionParticipantInformation] = useState<SfuSessionParticipantInformation>();
  const remoteViewHolder = useRef<HTMLDivElement>(null);
  const [initialSetupDone, setInitialSetupDone] = useState<boolean>(false); // this is used to prevent the useEffect from running multiple times]
  let [peers, setPeers] = useState<PeerInformations>({}); // this is "let" because we need to modify it in the useEffect. Otherwise, the content is not updated in the relevant places
  let [sfuPeer, setSfuPeer] = useState<Peer.Instance | null>(null);
  let [microphoneEnabled, setMicrophoneEnabled] = useState<boolean>(true);
  let [transceiverMetaData, setTransceiverMetaData] = useState<Record<string, MetaData>>({})
  let [danglingTracks, setDanglingTracks] = useState<DanglingTrackInformation[]>([]);
  const addToasterMessage = useToastStore((store) => store.addMessage);
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  const isEmbedded = searchParams.get("embedded") === "true" ? true : false;

  useEffect(() => {
    console.log("Trying to start initial Setup");

    const doInitialSetup = async () => {
      if (!signalR || signalR.state !== HubConnectionState.Connected) {
        console.log("SignalR not connected, waiting 1s for connection");
        setTimeout(doInitialSetup, 1000);
        return;
      }
      console.log("Beginning initial setup");
      const iceServers = await signalR!.invoke("GetIceServers");
      setIceServers(iceServers);
      setInitialSetupDone(true);
      console.log("Initial setup done");
    };
    doInitialSetup();

  }, [signalR]);

  useEffect(() => {
    if (!initialSetupDone) return;
    console.log("Starting connection setup");

    // load chat messages from server
    const loadChatMessages = async () => {
      const response = await fetch(`/api/sessioncontext/byshorturl/${urlShortCode}/chatmessages?sort=createdAt&sortDirection=ascending`, { method: "GET" });
      const data = await response.json();
      // @ts-ignore
      // eslint-disable-next-line react-hooks/exhaustive-deps
      chatMessages = data.items;
      setChatMessages(data.items);
    };

    const initLocalStream = async () => {
      console.log('Init local stream');
      try {
        const useHighQualityVideo = searchParams.get("lq") === "true" ? false : true;
        const constraints = useHighQualityVideo ?
          {
            audio: { channelCount: 2, sampleSize: 16, sampleRate: 48000 },
            video: {
              width: { max: 1280 },
              height: { max: 720 },
              frameRate: {
                min: 1,
                ideal: 24,
                max: 30
              }
            }
          } : {
          audio: {
            channelCount: 2,
            sampleSize: 16,
            sampleRate: 48000
          }, video: {
            width: 320,
            height: 240,
            frameRate: {
              ideal: 5,
              max: 30
            }
          }
        };

        let newlocalStream;
        try {
          newlocalStream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (err) {
          // lets try only audio
          try {
            newlocalStream = await navigator.mediaDevices.getUserMedia({ audio: { channelCount: 2, sampleSize: 16, sampleRate: 48000 } });
          } catch (err) {
            // everything failed, lets create a dummy stream
            newlocalStream = new MediaStream();
          }
        }
        selfView.current!.srcObject = newlocalStream;
        setLocalStream(newlocalStream);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        localStream = newlocalStream; // we update this state object here as well, because otherwise, we'd still have the old value until the end of the react render cycle
        console.log('Local stream initialization successfull');
      } catch (err) {
        console.log("Failed to get local video stream", err);
      } finally {
        console.log("Finished local stream initialisation");
      }
    };

    const stopLocalStream = async () => {
      console.log("Beginning to stop local stream");

      if (localStream) {
        localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      setLocalStream(undefined);

      console.log("Finished to stop local stream");
    };

    const createAndUploadScreenShot = (remoteConnectionId : string) => {
      // get the video tag. create a screenshot of it and upload it to the server
      const peer = peers[remoteConnectionId];
        if (!peer) return;
      const video = peer.VideoTag
      const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append("file", blob, "screenshot.png");
            const uploadResponse = await fetch(`/api/file/upload`, { method: "POST", body: formData });
            // get the temp id from the response
            if (uploadResponse.ok) {
              let responseJson = await uploadResponse.json();
              let tempId = responseJson.guid;

              // now create the entity
              let createFile: CreateFile = {
                fileName: "screenshot.png",
                temporaryFileId: tempId,
                contentType: "image/png",
              };
              const createFileResponse = await fetch(`/api/sessioncontext/byshorturl/${urlShortCode}/attachment`, {
                method: "POST",
                body: JSON.stringify(createFile),
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              
              if (createFileResponse.ok) {
                const createFileResponseJson: File = await createFileResponse.json();
                addToasterMessage({ title: "Screenshot", content: "Screenshot created successfully", lifeTime: 5000 });
                // send a chat message with the file attached
                onSendMessage("Screenshot", [createFileResponseJson.id]);
              } else {
                addToasterMessage({ title: "Screenshot", content: "Failed to create screenshot", lifeTime: 5000 });
              }
            } else {
              addToasterMessage({ title: "Screenshot", content: "Failed to create screenshot", lifeTime: 5000 });
              return;
            }
          }
        });
    };

    const createUserInfoString = (userInformation: UserInformation) => {
      // if a device name is set, use it

      if(userInformation.deviceName) {
        return userInformation.deviceName;
      }

      // if first & last name are set, use them, otherwise use the username
      if(userInformation.firstName && userInformation.lastName) {
        return `${userInformation.firstName} ${userInformation.lastName}`;
      }

      return userInformation.userName;
    }

    const createParticipantContainer = (remoteConnectionId: string, info: UserInformation) => {
      let peerContainer = document.createElement("div");
      peerContainer.id = `peercontainer-${remoteConnectionId}`;
      peerContainer.setAttribute("style", 'width: 100%; height: 100%; position: relative;');
      peerContainer.setAttribute("class", 'peercontainer');

      const iconStyles = "-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; width: 1em; height: 1em; display: inline-block; fill: currentColor; -webkit-flex-shrink: 0; -ms-flex-negative: 0; flex-shrink: 0; margin: var(--Icon - margin); font-size: var(--Icon-fontSize, var(--joy-fontSize-xl2)); color: var(--Icon-color, var(--joy-palette-text-icon, var(--joy-palette-neutral-500, #636B74)));";

      let toolBar = document.createElement("div");
      let screenshotButton = document.createElement("button");
      screenshotButton.setAttribute("style", "margin-left: 10px; display: inline-block; width: 28px; height: 28px; padding: 0px;");
      screenshotButton.innerHTML = `<svg style="${iconStyles}"><circle cx="12" cy="12" r="3.2"></circle><path d='M9 2 7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5'></path></svg>`;
      screenshotButton.onclick = (e: MouseEvent) => { createAndUploadScreenShot(remoteConnectionId) };

      const micOpen = `<svg style="${iconStyles}"><path d='M3 9v6h4l5 5V4L7 9zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77'></path></svg>`;
      const micMuted = `<svg style="${iconStyles}"><path d='M7 9v6h4l5 5V4l-5 5z'></path></svg>`;

      let audioMuteButton = document.createElement("button");
      audioMuteButton.setAttribute("style", "margin-left: 10px; display: inline-block; width: 28px; height: 28px; padding: 0px;");
      audioMuteButton.innerHTML = micOpen;

      let peerName = document.createElement("span");
      peerName.innerHTML = createUserInfoString(info);
      toolBar.appendChild(peerName);
      toolBar.appendChild(screenshotButton);
      toolBar.appendChild(audioMuteButton);

      peerContainer.appendChild(toolBar);

      let videoElement = document.createElement("video");
      peerContainer.appendChild(videoElement);

      videoElement.poster = "./placeholderRemote.jpg";
      videoElement.autoplay = true;
      videoElement.id = `video-${remoteConnectionId}`;
      videoElement.onclick = (e) => {
        remoteViewClick(e, remoteConnectionId)
      }
      videoElement.setAttribute("style", 'width: 100%; max-height: 90%; ');

      audioMuteButton.onclick = (e: MouseEvent) => {
        videoElement.muted = !videoElement.muted;
        audioMuteButton.innerHTML = videoElement.muted ? micMuted : micOpen;
      };

      remoteViewHolder.current?.appendChild(peerContainer);
      return { peerContainer, videoElement };
    }

    const getParticipantContainer = (remoteConnectionId: string) => {
      if (!remoteViewHolder.current) return null;
      return remoteViewHolder.current?.querySelector(`#peercontainer-${remoteConnectionId}`) as HTMLDivElement;
    }

    const removeParticipantContainer = (remoteConnectionId: string) => {
      if (!remoteViewHolder.current) return;
      let peerContainer = getParticipantContainer(remoteConnectionId);
      if (peerContainer) {
        remoteViewHolder.current?.removeChild(peerContainer);
      };
    }

    const createParticipant = async (remoteConnectionId: string, info: UserInformation) => {
      const { peerContainer, videoElement } = createParticipantContainer(remoteConnectionId, info);
      peers[remoteConnectionId] = {
        Stream: new MediaStream(), PeerContainer: peerContainer, VideoTag: videoElement, ConnectionLost: false, UserInformation: null };
      setPeers({ ...peers });
    }

    const createSfuPeer = async (initiate: boolean) => {
      let newPeer = new Peer({
        initiator: initiate,
        trickle: true,
        stream: localStream!,
        config: {
          iceServers: iceServers,
          iceCandidatePoolSize: 10,
          certificates: []
        }
      });
      newPeer.on("signal", (data) => {
        signalR!.invoke("SendSDPSignal", urlShortCode, "sfu", JSON.stringify(data));
      });

      // we need to bind this to the peerConnection directly, because simple-peer does not allow us to access the transceiver in the event directly
      newPeer.on("track", (track: MediaStreamTrack, stream: MediaStream, transceiver: RTCRtpTransceiver) => {
        danglingTracks.push({ track, mid: transceiver.mid! });
        setDanglingTracks(danglingTracks);
        matchMetaDataToTracks();
      });

      newPeer.on("stream", (stream: MediaStream) => {
        console.log("Got a stream", stream);
      });

      newPeer.on("error", (error) => {
        console.log(error);
        removeSfuPeer();
      });
      newPeer.on("end", () => {
        removeSfuPeer();
      });
      newPeer.on("close", () => {
        removeSfuPeer();
      });
      setSfuPeer(newPeer);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      sfuPeer = newPeer;
    }

    const matchMetaDataToTracks = async () => {
      let newDanglingTracks: DanglingTrackInformation[] = danglingTracks;
      danglingTracks.forEach(async (trackInformation) => {
        let mid = trackInformation.mid;
        // if mid is 0 or 1, ignore it, because it's the local stream
        if(mid === "0" || mid === "1") return;

        let remoteConnectionId: string | undefined;
        if (trackInformation.track.kind === "audio") {
          remoteConnectionId = Object.keys(transceiverMetaData).find(key => transceiverMetaData[key].audioMid === mid);
        } else if (trackInformation.track.kind === "video") {
          remoteConnectionId = Object.keys(transceiverMetaData).find(key => transceiverMetaData[key].videoMid === mid);
        }

        const peer = peers[remoteConnectionId!];
        if (!peer) {
          console.error("Got a track for an unknown peer", trackInformation);
          return;
        }

        console.log("Adding track to peer", remoteConnectionId, trackInformation.track);
        // remove the track from the dangling tracks
        newDanglingTracks = newDanglingTracks.filter((value) => value !== trackInformation);
        peer.Stream.addTrack(trackInformation.track);
        try {
          if (peer.VideoTag.srcObject !== peer.Stream) {
            peer.VideoTag.srcObject = peer.Stream;
          }
          await peer.VideoTag.play();
        } catch (ex) {
          console.error(ex);
        }

      });

      setDanglingTracks(newDanglingTracks);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      danglingTracks = newDanglingTracks;
    }

    const removeSfuPeer = async () => {
      if (!sfuPeer) return;
      sfuPeer.destroy();
      setSfuPeer(null);
      if (onConnectionError !== undefined) {
        onConnectionError();
      }
    };

    const removeParticipant = (remoteConnectionId: string) => {
      // get the peer that should be removed
      const peerToRemove = peers[remoteConnectionId];
      if (peerToRemove) {
        removeParticipantContainer(remoteConnectionId);
        delete peers[remoteConnectionId];
      }
      setPeers({ ...peers });
    };

    const sendJoin = async () => {
      const otp = searchParams.get("otp");
      if (!sfuPeer) {
        await createSfuPeer(false);
      } else {
        console.error("Got a please connect message for an already existing peer.");
      }
      const params : Record<string, string | null> = { "OTP": otp };
      const result: SignalRRequestResult<SfuSessionParticipantInformation> = await signalR!.invoke("SendJoin", urlShortCode, params);
      // show a toaster
      if (!result.success) {
        addToasterMessage({ title: "Error", content: result.errorMessage!, lifeTime: 10000 });
        console.error("Error joining session", result.errorMessage, result.errorCode);
        navigate("/");
      } else {
        setSessionParticipantInformation(result.additionalParameters);
      }
      return;

    }

    const processTransceiverMetadata = async (metaData: Record<string, any>) => {
      console.log("gotMedadata", metaData);
      Object.keys(metaData).forEach((remoteConnectionId) => {
        if (!peers[remoteConnectionId]) createParticipant(remoteConnectionId, metaData[remoteConnectionId].userInformation);
        peers[remoteConnectionId].UserInformation = metaData.userInformation;
      });


      // now find peers, that are no longer in the metaData
      Object.keys(peers).forEach((remoteConnectionId) => {
        if (!metaData[remoteConnectionId]) {
          removeParticipant(remoteConnectionId);
        }
      });
      setTransceiverMetaData(metaData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      transceiverMetaData = metaData;
      matchMetaDataToTracks();
    };

    const processRemoteSDPSignal = async (targetUrlShortCode: string, remoteConnectionId: string, targetConnectionId: string, signal: string) => {
      if (!sfuPeer) return;
      if (targetConnectionId !== signalR?.connectionId) {
        console.warn("Ignoring sdp signal for another connection.", signal);
        return;
      }
      if (targetUrlShortCode !== urlShortCode) {
        console.warn("Ignoring sdp signal for another session.", signal);
        return;
      }
      sfuPeer.signal(JSON.parse(signal));
    }

    const processChatMessage = async (targetUrlShortCode: string, remoteConnectionId: string, viewModel: ChatMessage) => {
      if (targetUrlShortCode !== urlShortCode) {
        console.warn("Ignoring message for another session.");
        return;
      }
      chatMessages.push(viewModel);
      const newChatMessages = [...chatMessages];
      setChatMessages(newChatMessages);
    }

    const processSend = async (targetUrlShortCode: string, remoteConnectionId: string, targetConnectionId: string, type: string, params: any) => {
      if (targetConnectionId !== signalR?.connectionId) {
        console.warn("Ignoring message for another connection.", type, params);
        return;
      }
      if (targetUrlShortCode !== urlShortCode) {
        console.warn("Ignoring message for another session.", type, params);
        return;
      }
      try {
        switch (type) {
          case "mouseinteraction":
            break;
          case "transceivermetadata":
            processTransceiverMetadata(JSON.parse(params));
            break;
          default:
            console.log("Unknown message:", type, params);
            break;
        }
        
      } catch (err) {
        console.error(err);
      }
    }


    // actual logic begins here

    const connect = async () => {
      await initLocalStream();
      signalR!.on("ProcessSend", processSend);
      signalR!.on("ProcessSDPSignal", processRemoteSDPSignal);
      signalR!.on("ProcessChatMessage", processChatMessage);
      await sendJoin();
      if (!isEmbedded) {
        await loadChatMessages();
      }
    };
    connect();

    return () => {
      if (signalR) {
        // disconnect all peers
        Object.keys(peers).forEach((connectionId) => {
          removeParticipant(connectionId);
        });
        removeSfuPeer();

        signalR.invoke("SendLeave", urlShortCode, {});
        signalR.off("ProcessSend", processSend);
        signalR.off("ProcessSDPSignal", processRemoteSDPSignal);
        signalR.off("ProcessChatMessage", processChatMessage);
        stopLocalStream();
      }
    };
  }, [initialSetupDone]);

  const onSendMessage = async (message: string, attachments?: string[]) => {
    //send the message via signalR
    const model = {
      text: message,
      attachments: undefined as string[] | undefined
    };
    if (attachments) {
      model.attachments = attachments;
    }
    await signalR!.invoke("SendChatMessage", urlShortCode, model);
  };

  const remoteViewClick = async (e: MouseEvent, connectionId: string) => {
    // get video track
    const peer = peers[connectionId];
    if (!peer) return;
    const videoTrack = peer.Stream.getVideoTracks()[0];
    if (!videoTrack) return;
    let timestamp = 0;
    // get the receiver for the video track with getReceivers
    //@ts-ignore
    const receiver = sfuPeer!._pc.getReceivers().find((receiver: RTCRtpReceiver) => receiver.track?.id === videoTrack.id);
    const sources: any[] = receiver.getSynchronizationSources();
    if (sources.length > 0) {
      timestamp = sources[0].rtpTimestamp;
    }

    const coordinates = getClickCoordinatesOnVideo(e);
    if (coordinates) {
      console.log(coordinates)
      const payload = {
        x: coordinates.x,
        y: coordinates.y,
        button: e.button,
        buttons: e.buttons,
        timestamp: timestamp
      };
      signalR!.invoke("SendMessage", urlShortCode, connectionId, "mouseinteraction", JSON.stringify(payload));
    }
  };



  const toggleFullscreen = () => {
    if (fullscreenElementRef.current) {
      let elem = fullscreenElementRef.current;
      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch((err: Error) => {
          alert(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
        setFullscreenEnabled(true);
      } else {
        document.exitFullscreen();
        setFullscreenEnabled(false);
      }
    }
  };

  const toggleScreenSharing = async () => {
    if (screenSharingStream) {
      stopScreenSharing();
    } else {
      startScreenSharing();
    } 
  };

  const toggleMicrophone = () => {
    localStream?.getAudioTracks().forEach((track) => {
      track.enabled = !microphoneEnabled;
    });
    setMicrophoneEnabled(!microphoneEnabled);
  };

  const startScreenSharing = async () => {
    const displayMediaOptions: DisplayMediaStreamOptions = {
      video: true,
      audio: false
    };

    try {
      screenSharingStream =
        await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);

      if (screenSharingStream === undefined) {
        setScreenSharingStream(undefined);
        return;
      }

      let videoTrack = screenSharingStream.getVideoTracks()[0];
      // run stopScrennSharing, if the stream has an error
      videoTrack.addEventListener('ended', () => {
        console.log('Screen sharing stopped.');
        stopScreenSharing();
      });

      setScreenSharingStream(screenSharingStream);
      selfView.current!.srcObject = screenSharingStream!;
      // replace the outgoing video track on the transceiver of the sfuPeer
      if (sfuPeer) {
        let videoTrack = screenSharingStream!.getVideoTracks()[0];
        let videoTransceiver = (sfuPeer as any)._pc.getTransceivers().find((transceiver: RTCRtpTransceiver) => transceiver.sender.track?.kind === "video");
        videoTransceiver.sender.replaceTrack(videoTrack);
      }

    } catch (err) {
      console.error(`Error: ${err}`);
      stopScreenSharing();
    }
  }

  const stopScreenSharing = async () => {

    if (screenSharingStream === undefined) return;

    // stop all tracks of the screen sharing stream
    screenSharingStream?.getTracks().forEach((track) => {
      track.stop();
    });

    setScreenSharingStream(undefined);

    selfView.current!.srcObject = localStream!;
    // replace the outgoing video track on the transceiver of the sfuPeer with the local video stream
    if (sfuPeer) {
      let videoTrack = localStream!.getVideoTracks()[0];
      let videoTransceiver = (sfuPeer as any)._pc.getTransceivers().find((transceiver: RTCRtpTransceiver) => transceiver.sender.track?.kind === "video");
      videoTransceiver.sender.replaceTrack(videoTrack);
    }
  }

  return (
    <div ref={fullscreenElementRef} style={{ position: "relative" }}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid xs={9}>
            <Card>
              <Typography level="title-lg">Remote video</Typography>
              {!initialSetupDone ? <LoadingIndicator /> :
                <Grid container spacing={2} sx={{ height: "72vh", overflow: "hidden", position: "relative" }}>
                  <div id={"#videogrid"} style={{ gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))", gridTemplateRows: "repeat(auto-fit, minmax(250px, 1fr))", width: "100%",height: "100%", position: "absolute", overflowY: "auto", display: "grid", gap: "10px" }} ref={remoteViewHolder} />
                </Grid>
              }
            </Card>
            <Alert style={{ marginTop: "10px" }} color="primary">
              <p><Trans>Click on the remote video to send mouse clicks to the remote device.</Trans></p>
            </Alert>
          </Grid>
          <Grid xs={3}>
            {!initialSetupDone ? <LoadingIndicator /> :
              <Card>
                <Typography level="title-lg">Local video</Typography>
                <video muted={true} poster="./placeholder.jpg" autoPlay={true} style={{ width: "100%" }} ref={selfView} />
              </Card>
            }
            <Card style={{ marginTop: 20 }}>
              <CardContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', flexGrow: '1' }}>
                <Tooltip title={fullscreenEnabled ? <Trans>Exit fullscreen</Trans> : <Trans>Enter fullscreen</Trans>}><Button style={{ marginTop: "10px" }} onClick={toggleFullscreen}>{fullscreenEnabled ? <FullscreenExit /> : <Fullscreen />}</Button></Tooltip>
                <Tooltip title={microphoneEnabled ? <Trans>Mute Microphone</Trans> : <Trans>Enable Microphone</Trans>}><Button style={{ marginTop: "10px" }} onClick={toggleMicrophone}>{microphoneEnabled ? <Mic /> : <MicOff />}</Button></Tooltip>
                <Tooltip title={screenSharingStream ? <Trans>Disable Screen Sharing</Trans> : <Trans>Enable Screen Sharing</Trans>}><Button style={{ marginTop: "10px" }} onClick={toggleScreenSharing}>{screenSharingStream ? <StopScreenShare /> : <ScreenShare />}</Button></Tooltip>
              </CardContent>
            </Card>
            <Card style={{ marginTop: 20 }}>
              <CardContent>
                {sessionParticipantInformation && <RecordingControl sessionParticipantInformation={sessionParticipantInformation} />}
                {sessionParticipantInformation && <MessagesPane currentUserInformation={sessionParticipantInformation} onSendMessage={onSendMessage} messages={chatMessages} />}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    </div>
  );
}
export { LiveSessionPage };
