import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import CircularProgress from '@mui/joy/CircularProgress';
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { useSearchParams, useParams } from "react-router-dom";
import { Breadcrumb } from '../../components/Breadcrumb';
import { useEffect } from 'react';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { DeviceSortField, SortDirection, DevicePrefilter } from '../../components/ListTypes';
import { Device } from '../../models/Device';
import { DeviceTable } from '../../components/DeviceTable';

const DeviceListPage = () => {
  const fetch = useAuthenticatedFetch();
  const { prefilter } = useParams();
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [devices, setDevices] = useState<Device[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [reloadTrigger, setReloadTrigger] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);
  const [sort, setSort] = useState<DeviceSortField>(DeviceSortField.Id);
  const { _ } = useLingui();
  let devicePrefilter: DevicePrefilter;

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof DeviceSortField;
    if (sort !== DeviceSortField[typedSortField]) {
      setSort(DeviceSortField[typedSortField]);
    }
  }

  // if filters is is not set or contains a invalid value, set it to all
  if (!prefilter || ["all"].indexOf(prefilter) < 0) {
    devicePrefilter = DevicePrefilter.All;
  } else {
    devicePrefilter = DevicePrefilter[prefilter as keyof typeof DevicePrefilter];
  }



  useEffect(() => {
    const getDevices = async () => {
      await setLoading(true)
      let url = `/api/Device?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const devices = await result.json();
        await setDevices(devices.items);
        await setTotalCount(devices.totalCount);
        await setSkip(devices.skip);
        await setTake(devices.take);
        await setLoading(false)
      }
    };
    getDevices();
  }, [skip, take, sortDirection, sort, prefilter, fetch, reloadTrigger]);

  const onDeleteDevice = async (id: string) => {
    let url = `/api/Device`;
    const result = await fetch(
      url,
      {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    if (result) {
      setReloadTrigger(new Date());
    }
  };

  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Breadcrumb crumbs={[{ title: _(msg`All devices`) }]} />
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            <Trans>Devices</Trans>
          </Typography>
        </Box>
        {loading ?
          <div style={{ display: "flex", height: "70vh" }}><div style={{ margin: 'auto', textAlign: "center" }}><CircularProgress variant="soft" /><br /><Trans>Loading...</Trans></div></div> :
          <>
            <DeviceTable devices={devices} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={devicePrefilter} onDeleteDevice={onDeleteDevice} />          </>
        }
      </Box>
    </Box>
  );
}

export { DeviceListPage };
