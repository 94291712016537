import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import Grid from '@mui/joy/Grid';
import { SignalRContext } from "../../App";
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import { Breadcrumb } from "../../components/Breadcrumb";
import { useAuthenticatedFetch } from '../../stores/fetch';
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import CircularProgress from '@mui/joy/CircularProgress';
import { useSearchParams } from "react-router-dom";
import { SessionContext, SessionContextState } from "../../models/SessionContext";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import MessagesPane from "../../components/chat/MessagesPane";
import { ChatMessage } from "../../models/ChatMessage";
import { Profile } from "../../models/Profile";
import { HubConnectionState } from "@microsoft/signalr";
import { SessionContextStateChip } from "../../components/SessionContextStateChip";
import { formatDate } from "../../utils/dateUtils";
import { CloseSessionContextButton } from "./CloseSessionContextButton";
import { Button } from "@mui/joy";
import { Link as RouterLink } from 'react-router-dom';


const ChatTab = () => {
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);
  const { _ } = useLingui();
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const [searchParams] = useSearchParams();

  const [signalR, signalRError] = useContext(SignalRContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [skip, setSkip] = useState<number>(0);
  const [initialSetupDone, setInitialSetupDone] = useState<boolean>(false); // this is used to prevent the useEffect from running multiple times]
  const [take, setTake] = useState<number>(20);
  let [messages, setMessages] = useState<ChatMessage[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const loadProfileFromBackend = useCallback(async () => {
    const url = "/api/Identity/myprofile";
    const result = await fetch(url, { method: "GET" });
    const profile: Profile = await result.json();
    setProfile(profile);
  }, [fetch]);

  useEffect(() => {
    loadProfileFromBackend();
  }, [loadProfileFromBackend]);

  useEffect(() => {
    console.log("Trying to start initial Setup");
    const getMessages = async () => {
      await setLoading(true)
      let url = `/api/SessionContext/${id}/chatMessages?sortDirection=Ascending&sort=CreatedAt`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const messagesResult = await result.json();
        //@ts-ignore
        messages = messagesResult.items;
        await setMessages(messagesResult.items);
        await setTotalCount(messagesResult.totalCount);
        await setSkip(messagesResult.skip);
        await setTake(messagesResult.take);
        await setLoading(false)
      }
    };
    getMessages();
    const getSessionContext = async () => {
      let url = `/api/SessionContext/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessionContext = await result.json();
        setSessionContext(sessionContext);
      }
    };
    const doInitialSetup = async () => {
      if (!signalR || signalR.state !== HubConnectionState.Connected) {
        console.log("SignalR not connected, waiting 1s for connection");
        setTimeout(doInitialSetup, 1000);
        return;
      }
      await signalR.invoke("SendRegisterForSessionContextEvents", id);
      signalR!.on("ProcessChatMessage", processChatMessage);
      setInitialSetupDone(true);
      console.log("Initial setup done");
    };

    getSessionContext();
    doInitialSetup();

    return () => {
      signalR!.off("ProcessChatMessage", processChatMessage);
    }

  }, [signalR]);

  const processChatMessage = async (urlShortCode: string, remoteConnectionId: string, viewModel: ChatMessage) => {
    messages.push(viewModel);
    const newChatMessages = [...messages];
    setMessages(newChatMessages);
  }

  const onSendMessage = (text: string) => {
    // post the message to the backend
    fetch(`/api/SessionContext/${id}/chatMessages`, {
      method: "POST",
      body: JSON.stringify({ text }),
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  return !sessionContext || !profile
    ? <LoadingIndicator />
    : <>
      <Grid container spacing={2}>
        <Grid xs={9}>
          <Card sx={{ flexGrow: 1, height: "80vh" }}>
            <Typography level="title-lg"><Trans>"{sessionContext.friendlyName}" ({sessionContext.shortURL}) Chat</Trans></Typography>
            {loading ?
              <div style={{ display: "flex", height: "80vh" }}><div style={{ margin: 'auto', textAlign: "center" }}><CircularProgress variant="soft" /><br /><Trans>Loading...</Trans></div></div> :
              <MessagesPane messages={messages} onSendMessage={onSendMessage} currentUserInformation={profile!.id} sx={{height: "100%"}} />
            }
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card>
            <Typography level="title-lg"><Trans>Session context information</Trans></Typography>
            <Typography level="body-sm"><Trans>Context ID: {sessionContext.id}</Trans></Typography>
            <Typography level="body-sm"><Trans>State: <SessionContextStateChip state={sessionContext.state} /></Trans></Typography>
            <Typography level="body-sm"><Trans>ShortURL: {id}</Trans></Typography>
            <Typography level="body-sm"><Trans>Created at: {formatDate(sessionContext.createdAt)}</Trans></Typography>
            <Typography level="body-sm"><Trans>Organisation: {sessionContext.organisationName}</Trans></Typography>
            {(sessionContext.state !== SessionContextState.Closed) && <>
              <CloseSessionContextButton sessionContext={sessionContext} onFinished={() => { }} />
              <Button component={RouterLink} to={`/livesession/${sessionContext.shortURL}`}><Trans>Enter session</Trans></Button>
            </>
            }
          </Card>

        </Grid>
      </Grid>
    </>
}

export { ChatTab };
