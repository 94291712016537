import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from "@lingui/macro";
import { SortDirection } from './ListTypes';

const MobilePagination = ({ skip = 0, take = 20, totalCount, prefilter = "all", sort, sortDirection }: { skip: number, take: number, totalCount: number, prefilter: string, sort: string, sortDirection: SortDirection }) => {
  // show next button if there are more pages
  const showNext = skip + take < totalCount;

  // show previous button if there are previous pages
  const showPrevious = skip > 0;

  // determine the currently selected page
  const selectedPage = skip / take + 1;

  const totalPages = Math.ceil(totalCount / take);

  return (
    <Box
      className="Pagination-mobile"
      sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
    >
      <IconButton
        aria-label="previous page"
        variant="outlined"
        disabled={!showPrevious}
        color="neutral"
        size="sm"
        to={`?skip=${skip - take}&take=${take}&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
        component={showPrevious ? RouterLink : IconButton}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography level="body-sm" mx="auto">
        <Trans>Page {selectedPage} of {totalPages}</Trans>
      </Typography>
      <IconButton
        aria-label="next page"
        variant="outlined"
        color="neutral"
        disabled={!showNext }
        size="sm"
        to={`?skip=${skip + take}&take=${take}&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
        component={showNext ? RouterLink : IconButton}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
}

export { MobilePagination };
